import { Suspense } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppContent, AppHeader, AppSidebar } from "./components";
import { useAppSelector } from "./core/feature/hooks";
import Routing from "./routes";
import "./scss/style.scss";
import { ChooseMenuVersion } from "./views/modal/chooseMenuVersion";
import { OtpComponent } from "./views/modal/OtpComponent";

const loading = (
  <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
        <AppContent />
      </div>
    </div>
  </div>
);

function App() {
  let isLoading = useAppSelector((state) => state.config.loading);
  let showOtp = useAppSelector((state) => state.config.showOtp);
  let username = useAppSelector((state) => state.otp.username);
  let password = useAppSelector((state) => state.otp.password);
  let transactionType = useAppSelector((state) => state.otp.transactionType);
  let deviceId = useAppSelector((state) => state.otp.deviceId);
  let otpTimeout = useAppSelector((state) => state.otp.otpTimeout);
  let showVersionMenu = useAppSelector(
    (state) => state.config.setShowVersionMenu
  );
  return (
    <BrowserRouter>
      <ToastContainer theme="colored"></ToastContainer>
      <OtpComponent
        key={`${showOtp ? "aktif" : "pasif"}${username}${transactionType}`}
        transactionType={transactionType}
        deviceId={deviceId}
        username={username}
        password={password}
        otpTimeout={otpTimeout}
      ></OtpComponent>
      <ChooseMenuVersion key={`${showVersionMenu ? "gerak" : "diam"}`} />
      <LoadingOverlay active={isLoading} spinner text="Harap Menunggu ...">
        <Suspense fallback={loading}>
          <Routes>
            {Routing.map((prop, index) => {
              return (
                <Route
                  key={"menu-" + index}
                  path={prop.path}
                  element={<prop.element />}
                />
              );
            })}
          </Routes>
        </Suspense>
      </LoadingOverlay>
    </BrowserRouter>
  );
}

export default App;
